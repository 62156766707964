import ProtectRoute from './components/ProtectRoute.jsx'
import { Route, Routes, useLocation } from 'react-router-dom'
import Login from './components/Login/Login.jsx'
import React, { useEffect, useRef, useState } from 'react'
import Organization from './pages/Organization/Organization.jsx'
import Permission from './pages/Permission/Permission.jsx'
import DeviceContainer from './components/Admin/Device/DeviceContainer.jsx'
import MqttContainer from './components/MqttBroker/MqttContainer.jsx'
import Cookies from 'js-cookie'
import UserDashboard from './pages/Homepage/UserDashboard.jsx'
import OrganizationList from './pages/Organization/OrganizationList.jsx'
import {
    AlertPopup,
    Container,
    handdleOpenAlert,
} from './components/StyleComponent.jsx'
import { useAuth } from './hook/useAuth.jsx'
import UserManagement from './pages/UserManagement/User_management.jsx'
import UserReport from './pages/Report/UserReport.jsx'
import UserDeviceIOT from './components/Admin/Organization/DeviceIot/UserDeviceIOT.jsx'
import PopupAlert from './components/PopupAlert.jsx'

import UserDeviceList from './components/Admin/Organization/DeviceIot/UserDeviceList.jsx'
import axiosInstance from './config/axiosConfig.jsx'
import { urlConfig } from './config/apiConfig.jsx'
import { LocalizationProvider } from '@mui/x-date-pickers'
import CustomAdapter from './utils/CustomAdapter.js'
import { AlertProvider } from './context/AlertContext.jsx'
import NotFound from './NotFound.jsx'
import NewGraph from './pages/Graph/NewGraph.jsx'

function App() {
    const [imageUrl, setImageUrl] = useState('')
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const match = location.pathname.match(/\/organizations\/([^/]+)/)
    const organizationId = match ? match[1] : ''

    const encodedAccessToken = Cookies.get('token')
    const accessToken = encodedAccessToken ? atob(encodedAccessToken) : null
    const [message, setMessage] = useState('')
    const [org, setOrg] = useState('')
    const [openAlert, setOpenAlert] = useState(false)
    const [alarm, setAlarm] = useState('')
    // const [reconnectAttempts, setReconnectAttempts] = useState(0)
    // const reconnectDelay = 1000

    const [connectedWB, setConnectedWB] = useState(false)
    const [typeCard, setTypeCard] = useState('')
    const [typeDevice, setTypeDevice] = useState('')
    const [typeAlarm, setTypeAlarm] = useState('')

    const [reconnectAttempts, setReconnectAttempts] = useState(0)
    const reconnectDelay = 1000

    const handleAlert = (message, status) => {
        handdleOpenAlert(setOpenAlert, setMessage, message, status)
    }

    const { auth } = useAuth()

    const superAdmin = auth?.Permissions.filter(
        (item) => item.name === 'superAdmin',
    )

    const isAdmin = auth?.group === 'Admin'

    const getOrg = async () => {
        try {
            const res = await axiosInstance.get(
                `${urlConfig.organization}/${auth.keyName}`,
            )
            setOrg(res.data.message)
            localStorage.setItem('timezone', res.data.message?.timezone)
        } catch (error) {
            handleAlert('Failed to get organization', error.response.message)
        }
    }
    const wsRef = useRef(null)
    const closeWebSocket = () => {
        if (wsRef.current) {
            console.log('Closing existing WebSocket connection')
            wsRef.current.onopen = null
            wsRef.current.onmessage = null
            wsRef.current.onerror = null
            wsRef.current.onclose = null
            wsRef.current.close()
            wsRef.current = null
            setConnectedWB(false)
        }
    }
    const connectWebSocket = () => {
        closeWebSocket()
        const wsUrl = `${urlConfig.wbRealTime}?token=${accessToken}`
        wsRef.current = new WebSocket(wsUrl)
        wsRef.current.onopen = () => {
            console.log('WebSocket connected user')
            setConnectedWB(true)
            setReconnectAttempts(0)
        }
        wsRef.current.onmessage = (message) => {
            const reciveData = JSON.parse(message.data)
            if (!reciveData.message) {
                if (!isAdmin && superAdmin && !superAdmin[0]) {
                    if (reciveData.type === 'card') {
                        setTypeCard(reciveData)
                    } else if (reciveData.type === 'device') {
                        setTypeDevice(reciveData)
                    } else {
                        setTypeAlarm(reciveData)
                    }
                } else if (organizationId) {
                    const data = reciveData.keyName === organizationId
                    if (data) {
                        if (reciveData.type === 'card') {
                            setTypeCard(reciveData)
                        } else if (reciveData.type === 'device') {
                            setTypeDevice(reciveData)
                        } else {
                            setTypeAlarm(reciveData)
                        }
                    }
                }
            }
        }
        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error)
            if (connectedWB) {
                setConnectedWB(false)
            }
        }
        wsRef.current.onclose = (event) => {
            if (connectedWB) {
                setConnectedWB(false)
            }
            console.log('WebSocket closed', event.reason)
            console.log(`Reconnecting... Attempt ${reconnectAttempts + 1}`)
            setReconnectAttempts((prev) => prev + 1)
            setTimeout(() => {
                if (wsRef.current.readyState === WebSocket.CLOSED) {
                    connectWebSocket()
                }
            }, reconnectDelay)
        }
    }

    const connectWebSocketAdmin = () => {
        closeWebSocket()
        const wsUrl = `${urlConfig.wbRealTime}?token=${accessToken}&keyName=${organizationId}`
        wsRef.current = new WebSocket(wsUrl)
        wsRef.current.onopen = () => {
            console.log('WebSocket connected admin')
            setConnectedWB(true)
            setReconnectAttempts(0)
        }
        wsRef.current.onmessage = (message) => {
            const reciveData = JSON.parse(message.data)

            if (!reciveData.message) {
                if (organizationId) {
                    const data = reciveData.keyName === organizationId
                    if (data) {
                        if (reciveData.type === 'card') {
                            setTypeCard(reciveData)
                        } else if (reciveData.type === 'device') {
                            setTypeDevice(reciveData)
                        } else {
                            setTypeAlarm(reciveData)
                        }
                    }
                }
            }
        }
        wsRef.current.onerror = (error) => {
            console.error('WebSocket error:', error)
            if (connectedWB) {
                setConnectedWB(false)
            }
        }
        wsRef.current.onclose = (event) => {
            if (connectedWB) {
                setConnectedWB(false)
            }
            console.log('WebSocket closed', event.reason)
            console.log(`Reconnecting... Attempt ${reconnectAttempts + 1}`)
            setReconnectAttempts((prev) => prev + 1)
            setTimeout(() => {
                if (wsRef.current.readyState === WebSocket.CLOSED) {
                    connectWebSocket()
                }
            }, reconnectDelay)
        }
    }

    useEffect(() => {
        if (auth && accessToken) {
            if (!isAdmin && !superAdmin[0]) {
                console.log('crash user')

                setTypeCard('')
                setTypeDevice('')
                setTypeAlarm('')
                connectWebSocket()
            } else if (organizationId) {
                console.log('crash admin')
                setTypeCard('')
                setTypeDevice('')
                setTypeAlarm('')
                connectWebSocketAdmin()
            }
        }
        return () => {
            if (wsRef.current) {
                wsRef.current.close()
            }
        }
    }, [auth, accessToken, organizationId])

    useEffect(() => {
        if (auth && auth.keyName) {
            getOrg()
        }
    }, [auth])

    const check = (action) =>
        auth?.Permissions.filter(
            (item) => item.action === action || item.name === 'superAdmin',
        )

    const Allow = (name, action) => {
        const allow = check(action)?.filter((item) => item.name === name)
        if (allow) {
            return !!allow[0]
        } else return false
    }

    return (
        <>
            <LocalizationProvider dateAdapter={CustomAdapter}>
                <AlertProvider>
                    <Routes>
                        <Route
                            path='/login'
                            element={
                                accessToken ? (
                                    <>ต้องการล็อกเอาท์หรือไม่</>
                                ) : (
                                    <Login />
                                )
                            }
                        />
                        <Route
                            element={<ProtectRoute handleAlert={handleAlert} />}
                        >
                            {accessToken ? (
                                <React.Fragment>
                                    <Route
                                        path='*'
                                        element={
                                            <Container>
                                                <NotFound />
                                            </Container>
                                        }
                                    />
                                    <Route
                                        path='/'
                                        element={
                                            (superAdmin && superAdmin[0]) ||
                                            isAdmin ? (
                                                <OrganizationList
                                                    create={Allow(
                                                        'organization',
                                                        'create',
                                                    )}
                                                    update={Allow(
                                                        'organization',
                                                        'update',
                                                    )}
                                                    handleAlert={handleAlert}
                                                />
                                            ) : (
                                                <>
                                                    {Allow(
                                                        'dashboard',
                                                        'read',
                                                    ) && (
                                                        <UserDashboard
                                                            typeCard={typeCard}
                                                            connectedWB={
                                                                connectedWB
                                                            }
                                                            // alarm={alarm}
                                                            imageUrl={imageUrl}
                                                            setImageUrl={
                                                                setImageUrl
                                                            }
                                                            org={org}
                                                        />
                                                    )}
                                                </>
                                            )
                                        }
                                    />
                                    <>
                                        {Allow('graph', 'read') && (
                                            <Route
                                                path='/graph-trend'
                                                element={
                                                    <UserDeviceList org={org} />
                                                }
                                            />
                                        )}
                                    </>
                                    {/* <Route
                                        path='/graph-trend'
                                        element={<UserDeviceList org={org} />}
                                    /> */}

                                    <Route
                                        path='/graph-trend/:deviceId'
                                        // element={<Graph org={org} />}
                                        element={<NewGraph org={org} />}
                                    />

                                    <>
                                        {Allow('organization', 'read') && (
                                            <Route
                                                path='/organizations'
                                                element={
                                                    <OrganizationList
                                                        create={Allow(
                                                            'organization',
                                                            'create',
                                                        )}
                                                        update={Allow(
                                                            'organization',
                                                            'update',
                                                        )}
                                                        remove={Allow(
                                                            'organization',
                                                            'delete',
                                                        )}
                                                        handleAlert={
                                                            handleAlert
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('organization', 'read') && (
                                            <Route
                                                path='/organizations/:organizationId'
                                                element={
                                                    <Organization
                                                        connectedWB={
                                                            connectedWB
                                                        }
                                                        typeAlarm={typeAlarm}
                                                        typeDevice={typeDevice}
                                                        typeCard={typeCard}
                                                        create={Allow(
                                                            'organization',
                                                            'create',
                                                        )}
                                                        update={Allow(
                                                            'organization',
                                                            'update',
                                                        )}
                                                        remove={Allow(
                                                            'organization',
                                                            'delete',
                                                        )}
                                                    />
                                                }
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('role permission', 'read') && (
                                            <Route
                                                path='/role-permission'
                                                element={
                                                    <Permission
                                                        create={Allow(
                                                            'role permission',
                                                            'create',
                                                        )}
                                                        update={Allow(
                                                            'role permission',
                                                            'update',
                                                        )}
                                                        remove={Allow(
                                                            'role permission',
                                                            'delete',
                                                        )}
                                                    />
                                                }
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('user management', 'read') && (
                                            <Route
                                                path='/user-management'
                                                element={
                                                    <UserManagement
                                                        create={Allow(
                                                            'user management',
                                                            'create',
                                                        )}
                                                        update={Allow(
                                                            'user management',
                                                            'update',
                                                        )}
                                                        remove={Allow(
                                                            'user management',
                                                            'delete',
                                                        )}
                                                    />
                                                }
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('device', 'read') && (
                                            <Route
                                                path='/devices'
                                                element={
                                                    <DeviceContainer
                                                        create={Allow(
                                                            'device',
                                                            'create',
                                                        )}
                                                        update={Allow(
                                                            'device',
                                                            'update',
                                                        )}
                                                        remove={Allow(
                                                            'device',
                                                            'delete',
                                                        )}
                                                    />
                                                }
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('mqtt broker', 'read') && (
                                            <Route
                                                path='/mqtt-broker'
                                                element={<MqttContainer />}
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('report', 'read') && (
                                            <Route
                                                path='/report'
                                                element={
                                                    <UserReport orgUser={org} />
                                                }
                                            />
                                        )}
                                    </>

                                    <>
                                        {Allow('device', 'read') && (
                                            <Route
                                                path='/device-iot'
                                                element={
                                                    <UserDeviceIOT
                                                        org={org}
                                                        typeDevice={typeDevice}
                                                        typeAlarm={typeAlarm}
                                                        connectedWB={
                                                            connectedWB
                                                        }
                                                    />
                                                }
                                            />
                                        )}
                                    </>
                                </React.Fragment>
                            ) : (
                                <Route path='*' element={<Login />} />
                            )}
                        </Route>
                        {/* <Route path='*' element={<NotFound></NotFound>} /> */}
                    </Routes>
                </AlertProvider>
            </LocalizationProvider>

            <AlertPopup
                open={openAlert}
                setOpen={setOpenAlert}
                message={message}
            />
            <PopupAlert open={open} handleClose={() => setOpen(false)} />
        </>
    )
}

export default App
