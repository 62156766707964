import {
    Box,
    Button,
    Grid,
    Paper,
    Snackbar,
    TextField,
    Typography,
    useMediaQuery,
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close'
import AddIcon from '@mui/icons-material/Add'
import { keyframes } from '@emotion/react'
import React, { forwardRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { urlConfig } from '../config/apiConfig'
import CircleIcon from '@mui/icons-material/Circle'

export const handdleOpenAlert = (setOpen, setMessage, detail, status) => {
    setOpen(true)
    setMessage({ status: status, name: detail })
}

export const Loadding = () => {
    return (
        <Box sx={{ width: '100%', padding: '20px' }} align='center'>
            <span className='loader'></span>
        </Box>
    )
}

export const Input = forwardRef((props, ref) => {
    const {
        required,
        onChange,
        value = '',
        defaultValue,
        readOnly,
        sx,
        type,
    } = props

    return (
        <TextField
            size='small'
            sx={{
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderRadius: '5px',
                    },
                },
            }}
            {...props}
            variant='outlined'
            type={type}
            inputRef={ref}
            readOnly={!!readOnly}
            onChange={onChange}
            {...props}
            required={!!required}
            disabled={!!readOnly}
            style={{
                transition: '0.3s ease-out',
                fontSize: '18px',
                width: '100%',
                borderRadius: '5px',
                ...sx,
            }}
        />
    )
})

export function TextArea({
    required,
    onChange,
    value,
    defaultValue,
    readOnly,
    onEdit,
}) {
    return (
        <textarea
            rows={4}
            readOnly={!!readOnly}
            onChange={onChange}
            value={value}
            defaultValue={defaultValue || ''}
            required={!!required}
            disabled={!!readOnly}
            style={{
                fontSize: '18px',
                marginTop: '5px',
                width: '100%',
                padding: '6px 10px',
                border: onEdit ? '1.5px solid green' : '1.5px solid grey',
                boxShadow: onEdit
                    ? 'rgba(53, 128, 46, 0.1) 0px 2px 8px 0px'
                    : '',
                borderRadius: '8px',
            }}
        />
    )
}

export function AlertPopup({ open, setOpen, message }) {
    return (
        <React.Fragment>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={() => setOpen(false)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                sx={{
                    '& .MuiSnackbarContent-root': {
                        border:
                            message.status === 'success'
                                ? '1px solid green'
                                : '1px solid red',
                        backgroundColor:
                            message.status === 'success'
                                ? '#EDF7ED'
                                : '#ffe8e8',
                        color: message.status === 'success' ? '#1E4620' : 'red',
                        display: 'flex',
                        // alignItems: 'center',
                    },
                }}
                message={
                    <div style={{ display: 'flex' }}>
                        {message.status === 'success' ? (
                            <CheckCircleIcon
                                sx={{
                                    marginRight: '8px',
                                    color: '#4CAF50',
                                }}
                            />
                        ) : (
                            <CancelIcon
                                sx={{ marginRight: '8px', color: 'red' }}
                            />
                        )}
                        <div>
                            <div style={{ fontSize: '16px' }}>
                                {message.status === 'success'
                                    ? 'SUCCESS'
                                    : 'ERROR'}
                            </div>
                            <div style={{ fontSize: '14px' }}>
                                {message.name}
                            </div>
                        </div>
                        <IconButton
                            size='small'
                            aria-label='close'
                            color='inherit'
                            onClick={() => setOpen(false)}
                            sx={{
                                position: 'absolute',
                                right: '10px',
                                top: '10px',
                            }}
                        >
                            <CloseIcon fontSize='small' />
                        </IconButton>
                    </div>
                }
            />
        </React.Fragment>
    )
}

export function Container({
    children,
    title,
    header,
    button,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    dashboard,
    summaryChildren,
    navigate,
    statusValue,
}) {
    const matches = useMediaQuery('(max-width:1472px)')
    const togoing = useNavigate()
    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                minWidth: minWidth ? minWidth : '',
                minHeight: minHeight ? minHeight : '100%',
                maxWidth: maxWidth ? maxWidth : '100%',
                maxHeight: maxHeight ? maxHeight : '100%',
                p: '60px 15px 15px',
                // mt: 5,

                bgcolor: '#d6dae1',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                {title && (
                    <Box
                        sx={{
                            width: '100%',
                            my: title ? 1 : 1,
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        {title}
                    </Box>
                )}
                {header && (
                    <Box
                        sx={{
                            width: '100%',
                            my: 1,
                            fontSize: '20px',
                            fontWeight: 'bold',
                        }}
                    >
                        <Box
                            sx={{
                                boxShadow:
                                    ' rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
                                display: 'flex',
                                borderRadius: '60px',
                                alignItems: 'center',
                                backgroundColor: 'white',
                                padding: ' 4px 5px',
                                color: 'grey',
                                // fontWeight: '200',
                                border: '1px solid lightgrey',
                                fontSize: '12px',
                                textTransform: 'uppercase',
                                position: 'relative',
                            }}
                        >
                            {statusValue && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        right: 20,
                                        display: 'flex',
                                        flexDirection: 'row',
                                        gap: 0.5,
                                    }}
                                >
                                    <CircleIcon
                                        sx={{
                                            fontSize: 20,
                                            color:
                                                statusValue !== 'Online'
                                                    ? 'primary.main'
                                                    : 'success.dark',
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            fontWeight: 700,
                                            textTransform: 'none',
                                            color:
                                                statusValue !== 'Online'
                                                    ? 'primary.main'
                                                    : 'success.dark',
                                        }}
                                    >
                                        {statusValue}
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                size='small'
                                sx={{
                                    borderRadius: '50px',
                                    width: '35px',
                                    height: '35px',
                                    border: '2px solid white',
                                    marginRight: '10px',
                                    background: header.color,
                                    boxShadow:
                                        'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {header.icon}
                            </Box>

                            <Box
                                sx={{
                                    transition: '0.3s ease-out',
                                    display: 'flex',
                                }}
                            >
                                <Box
                                    sx={{
                                        cursor: navigate && 'pointer',
                                        '&:hover': {
                                            color: navigate && '#A81E1A',
                                        },
                                        transition: '0.1s ease-out',
                                    }}
                                    onClick={() =>
                                        navigate
                                            ? togoing(navigate.navigate)
                                            : null
                                    }
                                >
                                    {header.title}
                                </Box>
                                &#160;
                                {navigate && `/ ${navigate.title}`}
                            </Box>
                        </Box>
                    </Box>
                )}

                {button && (
                    <Box>
                        <Button
                            onClick={button.action}
                            color='button'
                            variant='contained'
                            size='small'
                            sx={{
                                whiteSpace: 'nowrap',
                                marginRight: '5px',
                                borderRadius: '30px',
                                textTransform: 'none',
                                padding: '5px 20px',
                                fontWeight: '200',
                                textWrap: 'nowrap',
                            }}
                            startIcon={
                                <AddIcon
                                    sx={{
                                        width: '20px',
                                        height: '20px',
                                    }}
                                ></AddIcon>
                            }
                        >
                            Add {button.name}
                        </Button>
                    </Box>
                )}
            </Box>
            {summaryChildren && (
                <Grid
                    container
                    sx={{
                        width: '100%',
                        my: 3,
                    }}
                >
                    {summaryChildren}
                </Grid>
            )}
            <Box
                component={'main'}
                sx={{
                    display: 'flex',
                    justifyContent: matches ? 'flex-start' : 'center',
                    height: dashboard ? '850px' : '100%',
                }}
            >
                <Paper
                    sx={{
                        marginTop: !title && !header ? '15px' : '0',
                        flexGrow: 1,
                        // boxShadow: 'rgba(0, 0, 0, 0.25) 1.95px 1.95px 3.6px',
                        minWidth: dashboard ? '1400px' : 'auto',
                        maxWidth: dashboard ? '1850px' : 'auto',
                        maxHeight: dashboard ? '950px' : 'auto',
                        minHeight: dashboard ? '800px' : 'auto',
                        height: dashboard ? '850px' : '100%',
                        borderRadius: 2.5,
                        padding: !title ? '0' : '20px',
                        background: '#fcfcfc',
                        overflowY: 'auto',
                        boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
                    }}
                >
                    {children || null}
                </Paper>
            </Box>
        </Box>
    )
}

export default function CardDnd({
    name,
    sx,
    small,
    warn,
    data,
    type,
    cardMode,
    images,
}) {
    // const level1 = keyframes`
    //   0% { background-color: #a62923;  }
    //   50% { background-color:rgba(208, 51, 44,.8); color: white;  }
    //   100% { background-color:#a62923;  }
    // `

    //     const level1 = keyframes`
    //   0% { background-color: rgba(156, 42, 42, 0.9); /* Firebrick */ }
    //   50% { background-color: rgba(209, 40, 40, 0.9); /* Crimson with slight transparency */ color: white; }
    //   100% { background-color: rgba(156, 42, 42, 0.9); /* Firebrick */ }
    // `

    const level1 = keyframes`
  0% { background-color: #5E0E11; }
  50% { background-color:  #EA3230; color: white; }
  100% { background-color: #5E0E11;}
`

    //     const level2 = keyframes`
    //       0% { background-color: white;  }
    //       50% { background-color:rgba(253,166, 85,.8); color: white;  }
    //       100% { background-color: white;  }
    // `

    //     const level2 = keyframes`
    //   0% { background-color: rgba(255, 223, 0, 0.9); /* Bright Yellow */ }
    //   50% { background-color: rgba(201, 154, 12, 0.9); /* Darker Yellow (Amber) */ color: white; }
    //   100% { background-color: rgba(255, 223, 0, 0.9); /* Bright Yellow */ }
    // `

    const level2 = keyframes`
  0% { background-color:#554512;}
  50% { background-color: #F5C944; color: white; }
  100% { background-color:#554512; }
`

    const sizeCard = {
        normal: 0,
        medium: 5,
        large: 9,
    }[cardMode]

    const iconUse = images
        ? images.filter((item) => item.deviceType === data.device_type)
        : []

    const thisUseImg = iconUse.length > 0 && iconUse[0].image.message.image

    return (
        <Box
            sx={{
                width: '100%',
                ...sx,
                height: '100%',
                background: 'rgba(255, 255, 255,1)',
                padding: '8px 8px 4px 8px',
                // borderRadius: '15px',
                boxShadow: ' rgba(0, 0, 0, 0.24) 0px 3px 8px',
                display: 'flex',
                // animation: `${level1} 1s infinite`,
                animation:
                    data.status === 'Online'
                        ? warn &&
                          `${type === 'Remain state' ? level1 : level2} ${
                              type === 'Remain state' ? '2s' : '1s'
                          } infinite`
                        : '',
            }}
        >
            <Box sx={{ marginRight: '10px' }}>
                {/* <Box
                    sx={{
                        background:
                            data && data.device_status === 'Offline'
                                ? '#D64242'
                                : '#73BE30',
                        padding: '0px 4px',
                        fontWeight: 'bold',
                        color: 'white',
                        fontSize: '8px' + sizeCard,
                        borderRadius: '30px',
                        textTransform: 'uppercase',
                    }}
                >
                    {data && data.device_status}
                </Box> */}
                <Box
                    sx={{
                        padding: '0px 5px',
                        borderRadius: '30px',
                        fontSize: `calc(7px + ${sizeCard}px)`,
                        color: 'white',
                        background:
                            data.status === 'Offline' ? 'grey' : '#44ba34',
                        textAlign: 'center',
                    }}
                >
                    {data.status}
                </Box>
                <Box
                    sx={{
                        marginTop: '5px',
                        background: '#e6e6e6',
                        borderRadius: '50px',
                        border: '3px solid white',
                        height: '45px',
                        width: '45px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;',
                    }}
                >
                    {thisUseImg && (
                        <img
                            // src={}
                            src={
                                thisUseImg
                                    ? `${urlConfig.baseUrlAPI}/api/v1${thisUseImg}`
                                    : ''
                            }
                            height={'70%'}
                            width={'70%'}
                            alt=''
                            style={{
                                zIndex: 1,
                                // border: '1px solid red',
                                // objectFit: 'cover',
                                // borderRadius: '50px',
                            }}
                        />
                    )}
                </Box>
            </Box>

            <Box
                sx={{
                    width: '100%',
                    fontSize: `calc(10px +  ${sizeCard}px)`,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    textAlign: 'left',
                    color: '#3073BE',
                }}
                align='left'
            >
                <Box
                    sx={{
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        // animation: warn && `${level1} 1s infinite`,
                    }}
                >
                    {data && data.name}
                </Box>
                <Box
                    sx={{
                        textTransform: 'uppercase',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: `calc(8px +  ${sizeCard}px)`,
                        color: 'grey',

                        // border: '1px solid red',
                        // animation: warn && `${level1} 1s infinite`,
                    }}
                >
                    ID : {data && data.device_id}
                </Box>
                <Box
                    sx={{
                        fontSize: `calc(11px +  ${sizeCard}px)`,
                        color: 'black',
                    }}
                >
                    {data &&
                        data.params?.map((item, index) => (
                            <Box
                                key={index}
                                sx={{
                                    display: 'flex',
                                    marginBottom: '-3px',
                                    color:
                                        data.status === 'Offline'
                                            ? 'grey'
                                            : 'black',
                                }}
                            >
                                <div
                                    style={{
                                        width: '45%',
                                        // width: `calc(50px + (${sizeCard}) * 4)px `,
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        // border: '1px solid red',
                                    }}
                                >
                                    <span>
                                        {item.name.length > 10
                                            ? item.name.substring(0, 10) + '...'
                                            : item.name}
                                    </span>
                                </div>

                                <div
                                    style={{
                                        color: 'green',
                                        // width: '60px',
                                        // width: '20%',
                                        // textAlign: 'center',
                                        padding: '0 ',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        // textOverflow: 'ellipsis',
                                        marginLeft: '10px',
                                        // marginRight: '20px',
                                        width: '30%',
                                    }}
                                >
                                    {data.status === 'Offline'
                                        ? '--'
                                        : (item.value || 0).toFixed(2)}
                                </div>
                                <div
                                    style={{
                                        fontWeight: '200',
                                        // width: '50px',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        // textOverflow: 'ellipsis',
                                        // marginLeft: 'auto',
                                    }}
                                >
                                    {item.unit || ''}
                                </div>
                            </Box>
                        ))}
                </Box>
            </Box>
        </Box>
    )
}
